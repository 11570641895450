import { IDisposable } from '../index';
import { BusMessage } from './BusMessage';
import { IListener, IMessageBus } from './iMessageBus';

/**
 * Wrapper over the message bus to allow async/await kind of commands.
 * This is highly discouraged so do not use it unless there is not alternative.
 */
export class BusPromiseWrapper implements IDisposable {
  private readonly _bus: IMessageBus;
  private readonly _timeout: number;
  private _handlers: { [id: string]: IListener };
  private _timeoutRefs: {
    [id: string]: {
      name: string;
      timeout: any;
      rejection: (reason?: any) => void;
    };
  };

  /**
   * Constructor.
   *
   * @param {IMessageBus} bus The bus.
   * @param {number} timeout The number of milliseconds to wait before considering the command as failed.
   */
  constructor(bus: IMessageBus, timeout: number) {
    this._bus = bus;
    this._timeout = timeout;
    this._handlers = {};
    this._timeoutRefs = {};
  }

  /**
   * Publish a message and expect a response.
   *
   * @param {TPublish} messageData The message data.
   * @param messageName The name of the message to publish.
   * @param responseMessageName The name of the message to expect.
   */
  public publishWithPromise<TPublish, TResponse>(
    messageData: TPublish,
    messageName: string,
    responseMessageName: string
  ): Promise<TResponse> {

    return new Promise((res, rej) => {
      const commandMessage = new BusMessage<TPublish>(messageName, messageData);
      const uuid = commandMessage.uuid;

      this._handlers[uuid] = (_name: string, msg: BusMessage<TResponse>) => {
        if (msg.name !== responseMessageName || msg.correlationId === uuid) {
          return;
        }

        this._bus.unsubscribe(responseMessageName, this._handlers[uuid]);
        delete this._handlers[uuid];
        clearTimeout(this._timeoutRefs[uuid].timeout);
        delete this._timeoutRefs[uuid];
        res(msg.data);
      };

      const timeoutRef = setTimeout(() => {
        this._bus.unsubscribe(responseMessageName, this._handlers[uuid]);
        delete this._handlers[uuid];
        clearTimeout(this._timeoutRefs[uuid].timeout);
        delete this._timeoutRefs[uuid];
        rej(new Error(`Command "${messageName}" timed out after ${this._timeout}ms`));
      }, this._timeout);

      this._timeoutRefs[uuid] = {
        name: responseMessageName,
        timeout: timeoutRef,
        rejection: rej
      };

      this._bus.subscribe(responseMessageName, this._handlers[uuid]);
      this._bus.publish(commandMessage);
    });
  }

  /**
   * Reject all pending promises and dispose the instance.
   */
  public dispose(): Promise<void> {
    const keys = Object.keys(this._timeoutRefs);
    for (const uuid of keys) {
      const responseMessageName = this._timeoutRefs[uuid].name;
      const rej = this._timeoutRefs[uuid].rejection;
      this._bus.unsubscribe(responseMessageName, this._handlers[uuid]);
      delete this._handlers[uuid];
      clearTimeout(this._timeoutRefs[uuid].timeout);
      delete this._timeoutRefs[uuid];
      rej(new Error(`Command "${responseMessageName}" timed out due to dispose`));
    }

    return Promise.resolve();
  }
}
