

/**
 * A set of core constants related to DOM manipulation.
 */
const DOM_CONST = {
  ATTR_HOST_VERSION: 'data-host-version',
  ATTR_APP_NAME: 'data-app-name',
  ATTR_APP_VERSION: 'data-app-version',
  ATTR_DATE_OFFSET: 'data-date-time-offset'
};


export { DOM_CONST };
