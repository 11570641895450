import { getUuidV4 } from '../../../utilities/getUuidV4';

/**
 * MessageBus message.
 */
export class BusMessage<T> {

  /**
   * Create a new bus message.
   *
   * @param {String} name The name of the message.
   * @param {T} data The data of the message.
   * @param {string} correlationId The uuid for the message that this is a response to.
   * @param {string} uuid The uuid for the message in case you are re-publishing the message and need to preserve it.
   * @param {number} timestamp The timestamp for the message in case you are re-publishing the message and need to preserve it.
   */
  constructor(name: string, data: T, correlationId?: string, uuid?: string, timestamp?: number) {
    this.name = name;
    this.data = data;
    this.correlationId = correlationId;
    this.uuid = uuid || getUuidV4();
    this.timestamp = timestamp || new Date().getTime();
    this.metadata = {};
  }

  /**
   * The name of the message.
   */
  public name: string;
  /**
   * The message universal unique identifier.
   */
  public uuid: string;
  /**
   * The message uuid of the original message in case this is a `response` to another message.
   */
  public correlationId?: string;
  /**
   * The message timestamp.
   */
  public timestamp: number;
  /**
   * The message data.
   */
  public data: T;
  /**
   * The message data.
   */
  public metadata: { [key: string]: unknown };
}
