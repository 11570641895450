/**
 * State of the content.
 */
export enum IframeContentState {
  Mounted = 0,
  BeforeUpdate = 1,
  Updated = 2,
  Destroyed = 3
}

/**
 * Messages sent between the parent/child iframe components.
 */
export interface IIframeMessage {
  id: string;
  state?: IframeContentState;
  data?: string;
}
