import { IBackgroundWorker } from './iBackgroundWorker';

/**
 * An implementation that can compose multiple workers as one.
 */
export class ComposedBackgroundWorker implements IBackgroundWorker {
  private _workers: IBackgroundWorker[];

  /**
   * Constructor.
   *
   * @param {IBackgroundWorker[]} workers The workers to compose.
   */
  constructor(workers: IBackgroundWorker[]) {
    this._workers = workers;
  }

  public async initialize(): Promise<IBackgroundWorker> {
    await Promise.all(this._workers.map(m => m.initialize()));
    return this;
  }
  public async start(): Promise<void> {
    await Promise.all(this._workers.map(m => m.start()));
  }
  public async stop(): Promise<void> {
    await Promise.all(this._workers.map(m => m.stop()));
  }
  public async dispose(): Promise<void> {
    await Promise.all(this._workers.map(m => m.dispose()));
    this._workers = [];
  }
}
