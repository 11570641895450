import { Result } from './resultOfT';
import { unknownToError } from './unknownToError';

/**
 * Wrap an unsafe function in a try/catch block and return a Result<T>.
 *
 * @param {Function} fn The function to wrap.
 * @returns {Result<T>} The result of the function.
 */
export const safeWrap = <T>(fn: () => T): Result<T> => {
  const result = new Result<T>(true);
  try {
    result.value = fn();
  } catch (error) {
    result.error = unknownToError(error);
  }

  return result;
};
