import { IBackgroundWorker } from './iBackgroundWorker';

/**
 * Worker class for long running background tasks.
 */
export abstract class BackgroundWorker implements IBackgroundWorker {
  private _interval: number;
  private _intervalRef: number;
  private _immediate: boolean;
  private _initializePromise: Promise<BackgroundWorker> | null;
  protected readonly window: Window;

  /**
   * Constructor.
   *
   * @param {Window} window Reference to current window.
   * @param {Number} interval The number of milliseconds to wait between executions.
   * @param {Boolean} immediate Should this start immediately or wait the specified internal?
   */
  constructor(window: Window, interval: number, immediate: boolean) {
    this.window = window;
    this._interval = interval;
    this._immediate = immediate;
    this._intervalRef = 0;
    this._initializePromise = null;
  }

  /**
   * @inheritdoc
   */
  public async dispose(): Promise<void> {
    try {
      await this.stop();
    } catch {
      // Do nothing
    }
  }

  /**
   * Start the worker.
   */
  public start(): Promise<void> {
    this._intervalRef = this.window.setInterval(() => {
      void this.execute();
    }, this._interval);
    return this._immediate ? this.execute() : Promise.resolve();
  }

  /**
   * Stop the worker.
   */
  public stop(): Promise<void> {
    if (this._intervalRef) {
      this.window.clearInterval(this._intervalRef);
      this._intervalRef = 0;
    }

    return Promise.resolve();
  }

  /**
   * Initialize.
   */
  public initialize(): Promise<BackgroundWorker> {
    if (this._initializePromise) {
      return this._initializePromise;
    }

    this._initializePromise = this
      .initializeCore()
      .then(() => { return this; });

    return this._initializePromise;
  }
  /**
   * When overridden initializes the worker.
   */
  protected initializeCore(): Promise<void> { return Promise.resolve(); }
  /**
   * Executes this action periodically.
   */
  protected abstract execute(): Promise<void>;
}
