import { sendAsync } from '../../utilities';

export class BaseCustomizationManager {
  private _timezoneUrl: string;

  constructor(timezoneUrl: string) {
    this._timezoneUrl = timezoneUrl;
  }

  protected async convertToIanaTimezone(timeZone: string, countryCode?: string): Promise<string> {
    if (timeZone && timeZone.indexOf('/') === -1) {
      let url = `${this._timezoneUrl}?id=${encodeURIComponent(timeZone)}`;
      if (countryCode) {
        url += `&countryCode=${encodeURIComponent(countryCode)}`;
      }

      const response = await sendAsync({
        url: url,
        method: 'GET',
        headers: {
          'X-TrackerContext-Caller': 'cc_convertToIanaTimezone'
        }
      });

      return (JSON.parse(response.request.responseText) as { IANA: string }).IANA;
    }
    return timeZone;
  }
}
