/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LoggerOptions } from '@validide/logger';
import { CustomizationManager, CustomizationManagerOptions, ICustomizationManager, IHelpCenterService, IMarketingManager, INotificationsComponent, MarketingManager, NoopNotificationsComponent, ZendeskHelpCenterService } from '../../auxiliary-components/index';
import { AnonymousUserManager, ApiVersionChecker, ComposedBackgroundWorker, CookieVersionChecker, DateSkewChecker, IBackgroundWorker, II18nManager, ILoggerFactory, IMessageBus, IUserManager, LoggerFactory, MessageBus, RemoteI18nManager } from '../../core-components/index';
import { Types as MT } from '../../messages/index';
import { IHostApplication } from '../contracts/index';
import { ConfigurationKeys, HostEndpoints } from '../index';
import { BaseHostApplication } from './baseHostApplication';

/**
 * @inheritdoc
 */
export class HostApplication extends BaseHostApplication implements IHostApplication {

  /**
   * Resolve the message bus instance.
   */
  protected override async resolveMessageBus(): Promise<IMessageBus> {
    return new MessageBus();
  }

  /**
   * Resolve the logger factory instance.
   */
  protected override async resolveLoggerFactory(): Promise<ILoggerFactory> {
    const url = this.getHostUrl(HostEndpoints.log);
    const logLevel = LoggerOptions.getLevel(this.configuration[ConfigurationKeys.logLevel]! as string);
    return new LoggerFactory(this.window, url, 'POST', logLevel);
  }

  /**
   * Resolve the help center service instance.
   */
  protected override async resolveHelpCenter(): Promise<IHelpCenterService> {
    const customizationOptions = await this.customizationManager.getConfiguration();

    const helpCenter = new ZendeskHelpCenterService(
      this.window,
      {
        scriptUrl: this.configuration[ConfigurationKeys.zendeskUrl]! as string,
        locale: customizationOptions.culture,
        themeColor: customizationOptions.themeColors.primary
      }
    );

    await helpCenter.initialize();

    return helpCenter;
  }

  /**
   * Resolve the internationalization manager instance.
   */
  protected override async resolveI18nManger(): Promise<II18nManager> {
    const url = this.options.apiUrlTransformer!(`${(this.configuration[ConfigurationKeys.appRootPathAbsolute]! as string).replace(/\/$/g, '')}/i18n/PLACEHOLDER_LANG.json?__=PLACEHOLDER_VERSION`);
    const version = this.configuration[ConfigurationKeys.appVersion]! as string;
    return new RemoteI18nManager(
      url,
      version,
      this.loggerFactory.getLogger('RemoteI18nManager'),
      undefined,
      this.window.navigator.language,
      MT.E.CMN_I18N_RESOURCES_UPDATED,
      this.messageBus
    );
  }

  /**
   * Resolve the marketing manager instance.
   */
  protected override async resolveMarketingManager(): Promise<IMarketingManager> {
    return new MarketingManager(this.window, (this.configuration[ConfigurationKeys.marketingUrl]! as string));
  }

  /**
   * Resolve the user manager instance.
   */
  protected override async resolveUserManager(): Promise<IUserManager> {
    return new AnonymousUserManager();
  }

  /**
   * Resolve the customization manager instance.
   */
  protected override async resolveCustomizationManager(): Promise<ICustomizationManager> {
    const options = new CustomizationManagerOptions();
    options.realmUrl = this.configuration[ConfigurationKeys.realmUrl]! as string;
    options.themesUrl = this.configuration[ConfigurationKeys.themeUrl]! as string;
    options.timezoneUrl = this.getHostUrl(HostEndpoints.timezone);
    return new CustomizationManager(
      this.window,
      this.loggerFactory.getLogger('CustomizationManager'),
      options
    );
  }

  /**
   * Resolve the NotificationsComponent instance.
   */
  protected override async resolveNotificationsComponent(): Promise<INotificationsComponent> {
    this.logger!.info('The host is using the "NoopNotificationsComponent". In order to enable notifications override the "resolveNotificationsComponent" method on the application host.');
    return new NoopNotificationsComponent();
  }

  /**
   * Resolve the NotificationsComponent instance.
   */
  protected override async resolveDateChecker(): Promise<IBackgroundWorker> {
    return new DateSkewChecker(
      this.window,
      this.getHostUrl(HostEndpoints.dateTime),
      this.i18nManager,
      this.configuration[ConfigurationKeys.systemRequirementsUrl]! as string,
      this.messageBus,
      this.loggerFactory.getLogger('DateSkewChecker')
    );
  }

  /**
   * Resolve the NotificationsComponent instance.
   */
  protected override async resolveVersionChecker(): Promise<IBackgroundWorker> {
    return new ComposedBackgroundWorker([
      new ApiVersionChecker(
        this.window,
        this.getHostUrl(HostEndpoints.versions),
        this.messageBus,
        this.i18nManager,
        this.loggerFactory.getLogger('VersionChecker')
      ),
      new CookieVersionChecker(
        this.window,
        this.messageBus,
        this.i18nManager,
        `cmn_web_${this.configuration[ConfigurationKeys.appName]!}_r`,
        '1'
      )
    ]);
  }
}
