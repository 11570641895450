import { II18nManager } from '../../../../core-components';
import { ITransform, ITransformArgument } from '../../../contracts/';

/**
 * DefaultValueTransform - Transformer Class
 *
 * @param resourceManager used to inject the II18nManager
 * @returns new instance of DefaultValueTransform
 */
export class DefaultValueTransform implements ITransform {
  private _resourceManager: II18nManager;

  constructor(resourceManager: II18nManager) {
    this._resourceManager = resourceManager;
  }
  /**
   * method to transform a string to a specific default value
   *
   * @param value string to be translated
   * @param args { name: string, value: string }[] array of arguments that can be used to determine the default value of
   * the translation
   * @returns the translated default value if the value is empty
   */
  public transform(value: string, args: ITransformArgument[] = []) {
    const defaultValue = args.find(item => item.name === 'defaultValue')?.value;
    if ((!value || value.length === 0) && defaultValue) {
      return this._resourceManager.i18nTransform(defaultValue);
    }
    return this._resourceManager.i18nTransform(value);
  }
}
