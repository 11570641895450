const upperCaseFollowedByLowerCase = /\B([A-Z])(?=[a-z])/g;
const upperCasePrecededByLowerCase = /\B([a-z0-9])([A-Z])/g;

/**
 * Converts a kebab case string to a camel case string.
 *
 * @param {String} original The kebab case string (some-string).
 * @returns The original string in camel case (someString).
 */
export const kebabCaseToCamelCase = (original: string): string => {
  return original
    .split('-')
    .filter(f => f.length > 0)
    .map((item, index) => index
      ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
      : item.toLowerCase()
    )
    .join('');
};

/**
 * Converts a camel case string to a kebab case string.
 *
 * @param {String} original The camel case string (someString).
 * @returns The original string in kebab case (some-string).
 */
export const camelCaseToKebabCase = (original: string): string => {
  return original
    .replace(upperCaseFollowedByLowerCase, '-$1')
    .replace(upperCasePrecededByLowerCase, '$1-$2')
    .toLowerCase();
};
