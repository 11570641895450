export enum CookieSameSite {
  Lax = 'Lax',
  Strict = 'Strict',
  None = 'None'
};

/**
 * Cookie utilities class.
 */
export class CookieUtilities {
  private readonly _window: Window;

  /**
   * Constructor.
   *
   * @param {Window} window Reference to the current window.
   */
  constructor(window: Window) {
    this._window = window;
  }

  /**
   * Get the value of a cookie if it exists or return null.
   *
   * @param {String} name The cookie name.
   */
  public get(name: string): string | null {
    if (this._window.document.cookie.length === 0)
      return null;

    const result = this._window.document.cookie
      .split(';')
      .map((cookie: string) => {
        const parts = cookie.trim().split('=');
        return {
          name: parts[0],
          value: parts[1]
        };
      })
      .filter(cookie => { return cookie.name === name; });

    return (result.length > 0) ? result[0].value : null;
  }

  /**
   * Set the value of a cookie.
   *
   * @param {String} name The name of the cookie.
   * @param {String} value The value of the cookie. You should escape this value to make sure it's valid.
   * @param {Number} days The amount of time the cookie is valid.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public set(name: string, value: any, days: number | null = 0, sameSite?: CookieSameSite): void {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    let cookieString = `${name}=${value}`;
    if (days != null) {
      cookieString += `;expires=${CookieUtilities.getExpiry(days)}`;
    }
    cookieString += ';path=/';
    if (sameSite) {
      cookieString += `;SameSite=${sameSite}; Secure`;
    }
    this._window.document.cookie = cookieString;
  }

  /**
   * Remove a cookie.
   *
   * @param {String} name The name of the cookie.
   */
  public remove(name: string): void {
    this.set(name, '', -1);
  }

  public static getExpiry(days: number): string {
    if (!days) {
      return '';
    }
    return (new Date((new Date()).getTime() + (days * 24 * 60 * 60 * 1000))).toUTCString();
  }
}
