export interface ITransformRepository {
  register: (id: string, valueTransform: ITransform) => void;
  resolve: (id: string) => (ITransform | null);
}

export interface ITransformArgument {
  name: string;
  value: string;
}
export interface ITransform {
  transform: (originalValue: any, args?: ITransformArgument[]) => string;
}

export class NameValuePair<TName, TValue> {
  public name: TName;
  public value: TValue;

  constructor(name: TName, value: TValue) {
    this.name = name;
    this.value = value;
  }
}

export interface ITransformDescription {
  id: string;
  arguments?: NameValuePair<string, string>[];
}
