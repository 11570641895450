import { II18nDescription } from './core-components/index';

/**
 * Internationalization strings.
 */
const i18N: { [id: string]: II18nDescription } = {
  CMN_Error: {
    id: 'CMN_Error',
    fallback: 'Error'
  },
  CMN_DateIsSkewed: {
    id: 'CMN_DateIsSkewed',
    fallback: 'The date and time on your computer is not configured properly!'
  },
  CMN_WhatsNew: {
    id: 'CMN_WhatsNew',
    fallback: "What's new?"
  },
  CMN_NewVersionNotification: {
    id: 'CMN_NewVersionNotification',
    fallback: 'A new version of the application has been released.\nPlease refresh the page to get the latest version!'
  },
  CMN_UserUnit_millimeter_Regular: {
    id: 'CMN_UserUnit_millimeter_Regular',
    fallback: 'millimeter'
  },
  CMN_UserUnit_millimeter_Short: {
    id: 'CMN_UserUnit_millimeter_Short',
    fallback: 'mm'
  },
  CMN_UserUnit_meter_Regular: {
    id: 'CMN_UserUnit_meter_Regular',
    fallback: 'meter'
  },
  CMN_UserUnit_meter_Short: {
    id: 'CMN_UserUnit_meter_Short',
    fallback: 'm'
  },
  CMN_UserUnit_centimeter_Regular: {
    id: 'CMN_UserUnit_centimeter_Regular',
    fallback: 'centimeter'
  },
  CMN_UserUnit_centimeter_Short: {
    id: 'CMN_UserUnit_centimeter_Short',
    fallback: 'cm'
  },
  CMN_UserUnit_mm3_Regular: {
    id: 'CMN_UserUnit_mm3_Regular',
    fallback: 'mmÂ³'
  },
  CMN_UserUnit_mm3_Short: {
    id: 'CMN_UserUnit_mm3_Short',
    fallback: 'mmÂ³'
  },
  CMN_UserUnit_cm3_Regular: {
    id: 'CMN_UserUnit_cm3_Regular',
    fallback: 'cmÂ³'
  },
  CMN_UserUnit_cm3_Short: {
    id: 'CMN_UserUnit_cm3_Short',
    fallback: 'cmÂ³'
  },
  CMN_UserUnit_dm3_Regular: {
    id: 'CMN_UserUnit_dm3_Regular',
    fallback: 'dmÂ³'
  },
  CMN_UserUnit_dm3_Short: {
    id: 'CMN_UserUnit_dm3_Short',
    fallback: 'dmÂ³'
  },
  CMN_UserUnit_m3_Regular: {
    id: 'CMN_UserUnit_m3_Regular',
    fallback: 'mÂ³'
  },
  CMN_UserUnit_m3_Short: {
    id: 'CMN_UserUnit_m3_Short',
    fallback: 'mÂ³'
  },
  CMN_UserUnit_in3_Regular: {
    id: 'CMN_UserUnit_in3_Regular',
    fallback: 'inÂ³'
  },
  CMN_UserUnit_in3_Short: {
    id: 'CMN_UserUnit_in3_Short',
    fallback: 'inÂ³'
  },
  CMN_UserUnit_ft3_Regular: {
    id: 'CMN_UserUnit_ft3_Regular',
    fallback: 'ftÂ³'
  },
  CMN_UserUnit_ft3_Short: {
    id: 'CMN_UserUnit_ft3_Short',
    fallback: 'ftÂ³'
  },
  CMN_UserUnit_decimeter_Regular: {
    id: 'CMN_UserUnit_decimeter_Regular',
    fallback: 'decimeter'
  },
  CMN_UserUnit_decimeter_Short: {
    id: 'CMN_UserUnit_decimeter_Short',
    fallback: 'dm'
  },
  CMN_UserUnit_feet_Regular: {
    id: 'CMN_UserUnit_feet_Regular',
    fallback: 'feet'
  },
  CMN_UserUnit_feet_Short: {
    id: 'CMN_UserUnit_feet_Short',
    fallback: 'ft'
  },
  CMN_UserUnit_gallon_Regular: {
    id: 'CMN_UserUnit_gallon_Regular',
    fallback: 'gallon'
  },
  CMN_UserUnit_gallon_Short: {
    id: 'CMN_UserUnit_gallon_Short',
    fallback: 'gal'
  },
  CMN_UserUnit_liter_Regular: {
    id: 'CMN_UserUnit_liter_Regular',
    fallback: 'liter'
  },
  CMN_UserUnit_liter_Short: {
    id: 'CMN_UserUnit_liter_Short',
    fallback: 'l'
  },
  CMN_UserUnit_gram_Regular: {
    id: 'CMN_UserUnit_gram_Regular',
    fallback: 'gram'
  },
  CMN_UserUnit_gram_Short: {
    id: 'CMN_UserUnit_gram_Short',
    fallback: 'g'
  },
  CMN_UserUnit_kilogram_Regular: {
    id: 'CMN_UserUnit_kilogram_Regular',
    fallback: 'kilogram'
  },
  CMN_UserUnit_kilogram_Short: {
    id: 'CMN_UserUnit_kilogram_Short',
    fallback: 'kg'
  },
  CMN_UserUnit_ton_Regular: {
    id: 'CMN_UserUnit_ton_Regular',
    fallback: 'ton'
  },
  CMN_UserUnit_ton_Short: {
    id: 'CMN_UserUnit_ton_Short',
    fallback: 't'
  },
  CMN_UserUnit_inch_Regular: {
    id: 'CMN_UserUnit_inch_Regular',
    fallback: 'inch'
  },
  CMN_UserUnit_inch_Short: {
    id: 'CMN_UserUnit_inch_Short',
    fallback: 'in'
  },
  CMN_UserUnit_ounce_Regular: {
    id: 'CMN_UserUnit_ounce_Regular',
    fallback: 'ounce'
  },
  CMN_UserUnit_ounce_Short: {
    id: 'CMN_UserUnit_ounce_Short',
    fallback: 'ounce'
  },
  CMN_UserUnit_pint_Regular: {
    id: 'CMN_UserUnit_pint_Regular',
    fallback: 'pint'
  },
  CMN_UserUnit_pint_Short: {
    id: 'CMN_UserUnit_pint_Short',
    fallback: 'pt'
  },
  CMN_UserUnit_pound_Regular: {
    id: 'CMN_UserUnit_pound_Regular',
    fallback: 'pound'
  },
  CMN_UserUnit_pound_Short: {
    id: 'CMN_UserUnit_pound_Short',
    fallback: 'lb'
  },
  CMN_UserUnit_stone_Regular: {
    id: 'CMN_UserUnit_stone_Regular',
    fallback: 'stone'
  },
  CMN_UserUnit_stone_Short: {
    id: 'CMN_UserUnit_stone_Short',
    fallback: 'st'
  },
  CMN_UserUnit_yard_Regular: {
    id: 'CMN_UserUnit_yard_Regular',
    fallback: 'yard'
  },
  CMN_UserUnit_yard_Short: {
    id: 'CMN_UserUnit_yard_Short',
    fallback: 'yd'
  },
  CMN_UserUnit_metersPerSecond_Regular: {
    id: 'CMN_UserUnit_metersPerSecond_Regular',
    fallback: 'meters per second'
  },
  CMN_UserUnit_metersPerSecond_Short: {
    id: 'CMN_UserUnit_metersPerSecond_Short',
    fallback: 'm/s'
  },
  CMN_UserUnit_kilometersPerHour_Regular: {
    id: 'CMN_UserUnit_kilometersPerHour_Regular',
    fallback: 'kilometers per hour'
  },
  CMN_UserUnit_kilometersPerHour_Short: {
    id: 'CMN_UserUnit_kilometersPerHour_Short',
    fallback: 'km/h'
  },
  CMN_UserUnit_milesPerHour_Regular: {
    id: 'CMN_UserUnit_milesPerHour_Regular',
    fallback: 'miles per hour'
  },
  CMN_UserUnit_milesPerHour_Short: {
    id: 'CMN_UserUnit_milesPerHour_Short',
    fallback: 'mph'
  }
};

export { i18N };
