/**
 * Host configuration keys.
 */
export enum ConfigurationKeys {
  appName = 'APP_NAME',
  appVersion = 'APP_VERSION',
  appRootPathAbsolute = 'APP_ROOT_PATH_ABSOLUTE',
  appRootPathRelative = 'APP_ROOT_PATH_RELATIVE',
  cookiesInformationUrl = 'COOKIES_INFORMATION_URL',
  systemRequirementsUrl = 'SYSTEM_REQUIREMENTS_URL',
  dateTimeOffset = 'DATE_TIME_OFFSET',
  hostName = 'HOST_NAME',
  hostVersion = 'HOST_VERSION',
  hostRootPathAbsolute = 'HOST_ROOT_PATH_ABSOLUTE',
  hostRootPathRelative = 'HOST_ROOT_PATH_RELATIVE',
  logLevel = 'LOG_LEVEL',
  openIdAuthority = 'OPEN_ID_AUTHORITY',
  themeUrl = 'THEME_URL',
  realmUrl = 'REALM_URL',
  zendeskUrl = 'ZENDESK_URL',
  marketingUrl = 'MARKETING_URL',
  mainNavMenuUrl = 'MAIN_NAV_MENU_URL',
  supportUrl = 'SUPPORT_URL',
  userDropDownMenuUrl = 'USER_DROP_DOWN_MENU_URL',
  sideNavMenuUrl = 'SIDE_NAV_MENU_URL',
  apiRootUrl = 'API_ROOT_URL'
}

/**
 * Core application metadata keys
 */
export enum MetadataKeys {
  appNameAliases = 'APP_NAME_ALIASES',
}

/**
 * user functionalities related to host.
 */
export enum Functionalities {
  UserProfile = 'Profile_18700',
  WhatNew = 'WhatNew_18800',
  Settings = 'Settings_18900',
  Help = 'Help_19000',
  MarketingNotification = 'Mktg_Notif_101',
  Logout = 'Logout_00000'
}

/**
 * Host standard endpoints.
 */
export const enum HostEndpoints {
  /**
   * Extra configuration values.
   */
  configuration = '/.api/configuration',
  /**
   * Current server date and time.
   */
  dateTime = '/.api/dateTime',
  /**
   * Log ingestion endpoint.
   */
  log = '/.api/log',
  /**
   * Timezone conversion endpoint.
   */
  timezone = '/.api/timezone',
  /**
   * Host and application versions.
   */
  versions = '/.api/versions'
}
