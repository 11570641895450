/* eslint-disable no-unused-expressions */
/**
 * A promise wrapper around setTimeout.
 *
 * @param {Number} delay The number of milliseconds to delay the promise.
 * @param {Boolean} success Should the promise resolve or reject?
 */
export const getDelayPromise = (delay = 1, success = true): Promise<void> => {
  return new Promise((res, rej) =>{
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      success ? res() : rej();
    }, delay);
  });
};
