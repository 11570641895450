/**
 * Parse the query string and retunr the parameters.
 *
 * @param {String} queryString The query strig.
 */
export const getQueryParameters = (queryString: string): { [id: string]: string[] } => {
  const result: { [id: string]: string[] } = {};

  if (queryString) {
    const parts = (queryString.startsWith('?') ? queryString.slice(1): queryString).split('&');
    for (const part of parts) {
      const [key, value] = part.split('=');
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '');
    }
  }

  return result;
};
