import { ConsoleReporter, HttpReporter, HttpReporterOptions, ILogMessageEnricher, Logger, LoggerOptions, LogLevel, MultipleReporter } from '@validide/logger';
import { ILoggerFactory } from '../contracts/iLoggerFactory';


export class LoggerFactory implements ILoggerFactory {
  protected loggers: { [name: string]: Logger | undefined } = {};
  private readonly _window: Window;
  private readonly _endpoint: string;
  private readonly _verb: string;
  private readonly _minimumBatchSize: number;
  private readonly _interval: number;
  private readonly _logLevel: LogLevel;
  private readonly _enrichers : ILogMessageEnricher[];

  /**
   * Basic logger factory implementation.
   *
   * @param {window} window A reference to the window object.
   * @param {string} endpoint The HTTP endpoint to send the logs to.
   * @param {string} verb The HTTP verb to use when sending the logs.
   * @param {LogLevel} logLevel The minimum log level to report.
   * @param {number} minimumBatchSize The minimum batch size to report.
   * @param {number} interval The interval to wait for a batch to complete before reporting.
   * @param {ILogMessageEnricher[]} enrichers A collection of log message enrichers.
   */
  constructor(window: Window, endpoint: string, verb: string, logLevel: LogLevel, minimumBatchSize?: number, interval?: number, enrichers?: ILogMessageEnricher[]) {
    if (!window) {
      throw new Error('Missing window reference');
    }
    this._window = window;
    this._endpoint = endpoint;
    this._verb = verb || 'POST';
    this._logLevel = logLevel;
    this._minimumBatchSize = minimumBatchSize || 20;
    this._interval = interval || 2000;
    this._enrichers = enrichers || [];
  }

  /**
   * If a logger with that name exists, it returns it.
   * Otherwise, creates one with that name.
   *
   * @param {string} name
   */
  public getLogger(name: string): Logger {
    if (!name) {
      throw new Error('Missing name for the logger.');
    }

    if (this.loggers[name]) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.loggers[name]!;
    }

    const loggerOptions = this._createLoggerOptions();
    loggerOptions.name = name;
    const newLogger = new Logger(loggerOptions);

    // saves the new logger
    this.loggers[name] = newLogger;

    return newLogger;
  }

  /**
   * Creates the LoggerOptions based on the configurations set in constructor.
   */
  private _createLoggerOptions(): LoggerOptions {
    const httpReporterOptions = new HttpReporterOptions();
    httpReporterOptions.endpoint = this._endpoint;
    httpReporterOptions.verb = this._verb;
    httpReporterOptions.minimumBatchSize = this._minimumBatchSize;
    httpReporterOptions.interval = this._interval;

    const developmentMode = !!this._window.document.querySelector('body[data-development-mode="1"]');
    const logsReporter = developmentMode
      ? new MultipleReporter([
        new ConsoleReporter(console),
        new HttpReporter(httpReporterOptions)
      ])
      : new HttpReporter(httpReporterOptions);

    const loggerOptions = new LoggerOptions();
    loggerOptions.reporter = logsReporter;
    loggerOptions.minimumLevel = developmentMode ? LogLevel.Trace : this._logLevel;

    // set the enrichers
    this._enrichers.forEach((enricher: ILogMessageEnricher) => {
      loggerOptions.enrichers.push(enricher);
    });

    return loggerOptions;
  }

  public async dispose(): Promise<void> {
    for (const key of Object.keys(this.loggers)) {
      const logger = this.loggers[key];
      if (logger) {
        await logger.dispose();
      }
      this.loggers[key] = undefined;
    }
  }
}
