export enum LengthUnit {
  Millimeter,
  Centimeter,
  Decimeter,
  Meter,
  Inch,
  Feet,
  Yard
}

export enum VolumeUnit {
  CubicMillimeter,
  CubicCentimeter,
  CubicDecimeter,
  Liter,
  CubicMeter,
  OunceUnitedKingdom,
  PintUnitedKingdom,
  GallonUnitedKingdom,
  OunceUnitedStates,
  PintUnitedStates,
  GallonUnitedStates,
  CubicInch,
  CubicFoot
}

export enum WeightUnit {
  Gram,
  Kilogram,
  Ounce,
  Pound,
  StoneUnitedStates,
  StoneUnitedKingdom,
  Ton
}

export enum UnitSystem {
  Metric = 0,
  ImperialUnitedKingdom = 1,
  ImperialUnitedStates = 2
}

export enum SpeedUnit {
  MetersPerSecond,
  KilometersPerHour,
  MilesPerHour
}
