import { II18nManager } from '../../../../core-components';
import { ITransform } from '../../../contracts';
/**
 * TranslateTransform - Transformer Class
 *
 * @param resourceManager used to inject the II18nManager
 * @returns new instance of TranslateTransform
 */
export class TranslateTransform implements ITransform {
  private _resourceManager: II18nManager;

  constructor(resourceManager: II18nManager) {
    this._resourceManager = resourceManager;
  }

  /**
   * method to translate a string
   *
   * @param value string to be translated
   * @returns the translated value
   */
  public transform(value: string) {
    return this._resourceManager.i18nTransform(value);
  }
}

