import { IDisposable } from '../iDisposable';
import { BusMessage } from './BusMessage';

/**
 * Base message handler class.
 */
export abstract class MessageHandler<T> implements IDisposable {
  private readonly _messageNames: string[];

  /**
   * Constructor.
   *
   * @param {string[]} messageNames The list of messages this handler is interested in.
   */
  constructor(messageNames: string[]) {
    this._messageNames = messageNames;
  }
  /**
   * Initialize the handler.
   */
  public initialize(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * @inheritdoc
   */
  public async dispose(): Promise<void> {
    try {
      this._messageNames.splice(0, this._messageNames.length);
      await this.disposeCore();
    } catch {
      // Do nothing
    }
  }

  /**
   * The list of messages this handler is interested in.
   */
  public get messageNames(): string[] { return this._messageNames; }

  /**
   * Handle the message.
   *
   * @param {string} name The message name.
   * @param {BusMessage<T>} message The message data wrapper.
   */
  public handleMessage(name: string, message: BusMessage<T>): void {
    const messageName = name || message.name;
    if (this.messageNames.indexOf(messageName) === -1) {
      return;
    }
    return this.handleMessageCore(message);
  }

  /**
   * When overridden handles the given message.
   *
   * @param {BusMessage<T>} message The message data wrapper.
   */
  protected abstract handleMessageCore(message: BusMessage<T>): void;

  /**
   * When overridden disposes the derived class resources.
   */
  protected disposeCore(): Promise<void> {
    return Promise.resolve();
  }
}
