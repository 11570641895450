import * as PubSub from 'pubsub-js';
import { IMessageBus, BusMessage, IListener } from '../contracts/index';

/**
 * @inheritdoc
 */
export class MessageBus implements IMessageBus {
  /**
   * @inheritdoc
   */
  public subscribe(message: string, listener: IListener): IMessageBus {
    PubSub.subscribe(message, listener);
    return this;
  }

  /**
   * @inheritdoc
   */
  unsubscribe(_message: string, listener: IListener): IMessageBus {
    PubSub.unsubscribe(listener);
    return this;
  }

  /**
   * @inheritdoc
   */
  publish<T>(message: BusMessage<T>): IMessageBus {
    PubSub.publish(message.name, message);
    return this;
  }

  /**
   * @inheritdoc
   */
  public dispose(): Promise<void> {
    try {
      PubSub.clearAllSubscriptions();
    } catch {
      // Do nothing.
    }
    return Promise.resolve();
  }
}
