/**
 * Returns the pathname of an url.
 *
 * @param {Document} document
 * @param {string} url the given url
 * @returns {string} the pathname
 */
export const getPathname = (document: Document, url: string): string => {
  if (!url)
    return '';

  const a = document.createElement('a');
  a.setAttribute('href', url);

  return a.pathname;
};
