import { Logger, LogLevel } from '@validide/logger';
import { II18nManager, IMessageBus } from '../../../../core-components/index';
import { sendAsync } from '../../../../utilities/index';
import { DOM_CONST } from '../../index';
import { VersionChecker } from './versionCheckerService';
/**
 * A version checker implementation that relies on the server to know if a new version is available.
 */
export class ApiVersionChecker extends VersionChecker {
  private readonly _url: string;
  private readonly _logger: Logger;
  private readonly _hostVersion: string;
  private readonly _appVersion: string;
  private readonly _appName: string;
  /**
   * Constructor.
   *
   * @param {window} window Operating window object for class
   * @param {eventBus} eventBus Message Bus in which error messages are posted to
   * @param {II18nManager} i18nManager For translating outputs
   * @param {logger} logger Logger in which operations are written to
   * @param {url} url Base URL for component
   * @param {number} refreshInterval The interval (in milliseconds) between server side checks.
   * @param {number} autoRefreshDelay The interval (in milliseconds) before the application is automatically refreshed.
   */
  constructor(window: Window, url: string, eventBus: IMessageBus, i18nManager: II18nManager, logger: Logger, refreshInterval?: number, autoRefreshDelay?: number) {
    super(window, eventBus, i18nManager, refreshInterval ?? 4 * 60 * 60 * 1000, autoRefreshDelay); // 4 * 60 * 60 = 4 hour(s) * 60 minutes * 60 seconds * 1000 milliseconds
    this._url = url;
    this._logger = logger;
    this._hostVersion = this.window.document.body.getAttribute(DOM_CONST.ATTR_HOST_VERSION) || '';
    this._appVersion = this.window.document.body.getAttribute(DOM_CONST.ATTR_APP_VERSION) || '';
    this._appName = this.window.document.body.getAttribute(DOM_CONST.ATTR_APP_NAME) || '';
  }

  private _newerVersionAvailable(hostVersion: string, appVersion: string): boolean {
    if (this._hostVersion.length === 0 || this._appVersion.length === 0) {
      return false;
    }
    if (this._hostVersion === hostVersion && this._appVersion === appVersion) {
      return false;
    }
    return true;
  }

  private _getRequestUrl(): string {
    let url = this._url;
    let delimiter = '?';
    const params: { [id: string]: string } = {
      app: this._appName,
      path: this.window.location.href.split('#')[0]
    };
    Object.keys(params).forEach(item => {
      if (delimiter === '?' && url.indexOf('?') !== -1) {
        delimiter = '&';
      }
      url += `${delimiter}${encodeURIComponent(item)}=${encodeURIComponent(params[item])}`;
    });
    url += `${delimiter}_=${new Date().getTime()}`;
    return url;
  }

  protected override async isNewerVersionAvailable(): Promise<boolean> {
    const result = await sendAsync({
      url: this._getRequestUrl(),
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-TrackerContext-Caller': 'cc_apiVersionChecker'
      }
    });
    let success = false;
    let responseObject: any | null = null;
    if (result.request.status === 200) {
      try {
        responseObject = JSON.parse(result.request.responseText);
        success = true;
      } catch (error: any) {
        this._logger.log(LogLevel.Error, '"execute" failed', error as Error);
      }
    }

    if (success) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return this._newerVersionAvailable(responseObject.HOST_VERSION, responseObject.APP_VERSION);
    } else {
      this._logger.log(LogLevel.Warning, 'VersionChecker failed to get data from server', undefined, {
        'status': result.request.status,
        'statusText': result.request.statusText
      });
    }
    return false;
  }
}
