import { IShowNotification } from '../../../messages';
import { INotificationsComponent } from '../../contracts/index';

/**
 * An implementation of the notifications component that does nothing.
 */
export class NoopNotificationsComponent implements INotificationsComponent {
  /**
   * @inheritdoc
   */
  initialize(): Promise<void> {
    return Promise.resolve();
  }
  /**
   * @inheritdoc
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function, no-empty-function
  notify(_notification: IShowNotification): void {
  }
  /**
   * @inheritdoc
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function, no-empty-function
  clearNotifications(_group?: string | undefined): void {
  }
  /**
   * @inheritdoc
   */
  dispose(): Promise<void> {
    return Promise.resolve();
  }

}
