import { IProfile } from './iProfile';

export interface IUserData {
  /** The access token returned from the provider. */
  readonly accessToken: string;
  /** The claims represented by the user info endpoint. */
  readonly profile: IProfile;
  /** The unix timestamp (seconds) expiration date returned by the provider. */
  readonly expiresAt: number;
  /** The custom state transferred in the last sign in */
  readonly state: string;
}

export interface IUser extends IUserData {
  /** Calculated value indicating if the access token is expired */
  readonly expired: boolean;
}

export class User implements IUserData {
  private _data: IUserData;

  /**
   * Constructor.
   *
   */
  constructor(data: IUserData) {
    this._data = data;
  }
  /**
   * @inheritdoc
   */
  public get accessToken(): string {
    return this._data.accessToken;
  }
  /**
   * @inheritdoc
   */
  public get profile(): IProfile {
    return this._data.profile;
  }
  /**
   * @inheritdoc
   */
  public get expiresAt(): number {
    return this._data.expiresAt;
  }
  /**
   * @inheritdoc
   */
  public get state(): any {
    return this._data.state;
  }

  /**
   * @inheritdoc
   */
  public get expired(): boolean {
    return (Math.floor(new Date().getTime() / 1000)) >= this._data.expiresAt;
  }
}
