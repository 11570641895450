import DOMPurify from 'dompurify';

export class DomUtilities {
  private readonly _window: Window;

  /**
   * Constructor.
   *
   * @param {Window} window Reference to the current window.
   */
  constructor(window: Window) {
    this._window = window;
  }

  /**
   * Get the version of Internet Explorer if we are running on it. Returns -1 otherwise
   */
  public getIEVersion(): number {
    let rv = -1;
    let regExp;
    if (this._window.navigator.appName === 'Microsoft Internet Explorer') {
      regExp = new RegExp('MSIE ([0-9]{1,}[\\.0-9]{0,})');
      if (regExp.exec(this._window.navigator.userAgent) != null) {
        rv = parseFloat(RegExp.$1);
      }
    } else if (this._window.navigator.appName === 'Netscape') {
      regExp = new RegExp('Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})');
      if (regExp.exec(this._window.navigator.userAgent) != null) {
        rv = parseFloat(RegExp.$1);
      }
    }
    return rv;
  }

  /**
   * Checks if the current browser is Internet Explorer
   */
  public isIE(): boolean {
    return this.getIEVersion() !== -1;
  }

  /**
   * Returns a DomPurify sanitizer.
   */
  private get_dom_purify(): any {
    return DOMPurify(this._window).sanitize;
  };

  /**
   * Sanitized an HTML string, considering the given options.
   * For a list of possible options, check https://www.npmjs.com/package/dompurify.
   *
   * @param {string} dirty The string to be sanitized
   * @param {any} options An object with options for sanitization
   * @returns The sanitized string
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public sanitizeHtml(dirty: string, options?: any): string {
    /* eslint-disable @typescript-eslint/no-unsafe-return */
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    return this.get_dom_purify()(dirty, options);
    /* eslint-enable @typescript-eslint/no-unsafe-call */
    /* eslint-enable @typescript-eslint/no-unsafe-return */  }
}
