import { IHelpCenterService } from '../../contracts/index';

/**
 * An implementation of a help center service that does nothing.
 */
export class NoopHelpCenterService implements IHelpCenterService {
  /**
   * @inheritdoc
   */
  public initialize(): Promise<void> {
    return Promise.resolve();
  }
  /**
   * @inheritdoc
   */
  public openWidget(): void {
    /* NOOP */
  }
  /**
   * @inheritdoc
   */
  public closeWidget(): void {
    /* NOOP */
  }
  /**
   * @inheritdoc
   */
  public showWidget(): void {
    /* NOOP */
  }
  /**
   * @inheritdoc
   */
  public hideWidget(): void {
    /* NOOP */
  }
  /**
   * @inheritdoc
   */
  public setLocale(): void {
    /* NOOP */
  }
  /**
   * @inheritdoc
   */
  public setHelpCenterSuggestions(): void {
    /* NOOP */
  }

}
