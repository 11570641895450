export const Types = {
  /**
   * Commands.
   */
  C: {
    /**
     * Command to show another MFE.
     */
    CMN_MFE_SHOW: 'CMN.MFE.SHOW',
    /**
     * Command to remove a MFE.
     */
    CMN_MFE_REMOVE: 'CMN.MFE.REMOVE',
    /**
     * Command to show another MFE.
     */
    CMN_MFE_PROCESS_SHOW: 'CMN.MFE.PROCESS_SHOW',
    /**
     * Command to open the help center widget.
     */
    CMN_HELP_OPEN_WIDGET: 'CMN.HELP.OPEN_WIDGET',
    /**
     * Command to set the help center suggestion.
     */
    CMN_HELP_SET_SUGGESTION: 'CMN.HELP.SET_SUGGESTION',
    /**
     * Command to show a notification.
     */
    CMN_NOTIFICATION_SHOW: 'CMN.NOTIFICATION.SHOW',
    /**
     * Command to clear notifications.
     */
    CMN_NOTIFICATION_CLEAR: 'CMN.NOTIFICATION.CLEAR',
    /**
     * Command to clear notifications Group.
     */
    CMN_NOTIFICATION_CLEAR_GROUP: 'CMN.NOTIFICATION.CLEAR_GROUP',
    /**
     * Command to navigate to a url.
     */
    CMN_NAVIGATION_GO_TO: 'CMN.NAVIGATION.GOTO',
    /**
     * Command to sign-out the user.
     */
    CMN_USER_SIGNOUT: 'CMN.USER.SIGNOUT'
  },
  /**
   * Events.
   */
  E: {
    /**
     * Event to notify the MFE child changed it's size.
     */
    CMN_MFE_RESIZED: 'CMN.MFE.RESIZED',
    /**
     * Event to notify that the user has changed.
     */
    CMN_USER_CHANGED: 'CMN.USR.CHANGED',
    /**
     * Event to notify that the customization has changed.
     */
    CMN_CUSTOMIZATION_CHANGED: 'CMN.CUSTOMIZATION.CHANGED',
    /**
     * Event to notify that the i18n resources have changed culture.
     */
    CMN_I18N_RESOURCES_UPDATED: 'CMN.I18N.RESOURCES_UPDATED',
    /**
     * Event to notify that the url has changed (navigation occurred).
     */
    CMN_NAVIGATION_CHANGED: 'CMN.NAVIGATION.CHANGED'
  }
};

export * from './commands/index';
export * from './events/index';

