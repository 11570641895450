import { LowLevelStorage, LowLevelStorageOptions } from './low-level-storage';

export class SessionStorageWrapper extends LowLevelStorage {
  protected static _isAvailable: boolean | null = null;
  private window: Window;

  constructor(window: Window, options: LowLevelStorageOptions) {
    super(options);
    this.window = window;
  }

  public isAvailable(): boolean {
    if (SessionStorageWrapper._isAvailable !== null) {
      return SessionStorageWrapper._isAvailable;
    }

    SessionStorageWrapper._isAvailable = false;
    try {
      if (typeof(this.window.sessionStorage) === 'object') {
        const value = new Date().getTime().toString();
        const key = `common_components_session_storage_test_key_${value}`;
        this.window.sessionStorage.setItem(key, value);
        const isAvailable = this.window.sessionStorage.getItem(key) === value;
        this.window.sessionStorage.removeItem(key);

        // Keep this the last so in case one of the other method calls fail we know not to use sessionStorage
        SessionStorageWrapper._isAvailable = isAvailable;
      }
    } catch (e) {
      console.log(e);
    }

    return SessionStorageWrapper._isAvailable;
  }

  public getItem(key: string): string | null {
    return this.window.sessionStorage.getItem(this.getKey(key));
  }

  public setItem(key: string, value: string): void {
    this.window.sessionStorage.setItem(this.getKey(key), value);
  }
  public removeItem(key: string): void {
    this.window.sessionStorage.removeItem(this.getKey(key));
  }
  public clear(): void {
    this.window.sessionStorage.clear();
  }
}
