/**
 * Convert an RGB string to HEX value.
 *
 * @param rgbString The RGB[A] color string.
 */
export const rgbToHex = (rgbString: string): string => {
  const rgb = rgbString.substr(4).split(')')[0].split(',');

  let r = (+rgb[0]).toString(16);
  let g = (+rgb[1]).toString(16);
  let b = (+rgb[2]).toString(16);

  r = (r.length === 1) ? '0' + r : r;
  g = (g.length === 1) ? '0' + g : g;
  b = (b.length === 1) ? '0' + b : b;

  return '#' + r + g + b;
};
