import { Result } from './resultOfT';
import { safeWrap } from './safeWrap';

/**
 * Parse a JSON string into a Result<T>.
 *
 * @param {String} json The JSON string to parse.
 * @returns {Result<T>} The result of the parse.
 */
export const parseJSON = <T>(json: string): Result<T> => safeWrap(() => JSON.parse(json) as T);
