import { Logger, LogLevel } from '@validide/logger';
import { loadResource } from '../../../src/utilities/index';
import { IUserData } from '../../core-components/index';
import { getClassColor, getQueryParameters, sendAsync } from '../../utilities/index';
import { getCustomizationOptionsFromDictionary } from '../contracts/customizationManager/utilities';
import { CustomizationOptions, ICustomizationManager, ICustomizationOptions } from '../contracts/index';
import { BaseCustomizationManager } from './baseCustomizationManager';

export class CustomizationManagerOptions {
  public timezoneUrl = '';
  public themesUrl = '';
  public themesPlaceholder = '{THEME_UUID}';
  public dateModifiedPlaceholder = '{DATE_MODIFIED}';
  public realmUrl = '';
  public realmPlaceholder = '{REALM_UUID}';
  public themePrimaryClassName = 'text-primary';
  public themeSecondaryClassName = 'text-secondary';
  public chartPrimaryClassName = 'cs-chart-primary';
  public chartPrimaryTransparentClassName = 'cs-chart-primary-trans';
  public chartSecondaryClassName = 'cs-chart-secondary';
  public chartSecondaryTransparentClassName = 'cs-chart-secondary-trans';
  public stylesLoadTimeout = 1_000;
}

/**
 * Basic implementation of ICustomizationManager.
 */
export class CustomizationManager extends BaseCustomizationManager implements ICustomizationManager {
  protected readonly window: Window;
  protected readonly logger: Logger;
  protected readonly options: CustomizationManagerOptions;
  private _userSub: string;
  private _userExpires: number;
  private _userData: IUserData | null;
  private _optionsPromise: Promise<ICustomizationOptions> | null;

  /**
   * Constructor.
   *
   * @param {Window} window Reference to the current window.
   * @param {Logger} logger Logger instance.
   * @param {CustomizationManagerOptions} options Manger options.
   */
  constructor(
    window: Window,
    logger: Logger,
    options: CustomizationManagerOptions
  ) {
    super(options.timezoneUrl);
    this.window = window;
    this.logger = logger;
    this.options = options;
    this._optionsPromise = null;
    this._userSub = '';
    this._userExpires = 0;
    this._userData = null;
  }

  private async _logAndContinue<T>(promiseFn: () => Promise<T>): Promise<T | undefined> {
    try {
      const result = await promiseFn();
      return result;
    } catch (error: any) {
      this.logger.log(LogLevel.Error, 'CustomizationManager._logAndContinue failed', error as Error);
    }
    return;
  }

  private _getStyleOptions(): Partial<ICustomizationOptions> {
    return {
      themeColors: {
        primary: getClassColor(this.window, this.options.themePrimaryClassName),
        secondary: getClassColor(this.window, this.options.themeSecondaryClassName)
      },
      chartColors: {
        primary: getClassColor(this.window, this.options.chartPrimaryClassName),
        primaryTransparent: getClassColor(this.window, this.options.chartPrimaryTransparentClassName),
        secondary: getClassColor(this.window, this.options.chartSecondaryClassName),
        secondaryTransparent: getClassColor(this.window, this.options.chartSecondaryTransparentClassName)
      }
    };
  }

  /**
   * Get the customization options from the query string.
   */
  protected getQueryOptions(): Partial<ICustomizationOptions> {
    return getCustomizationOptionsFromDictionary(
      getQueryParameters(this.window.location.search)
    );
  }

  /**
   * Get the options related to the realm.
   *
   * @param {string} realm The realm identifier.
   * @returns
   */
  protected async getRealmOptions(realm?: string): Promise<Partial<ICustomizationOptions> | undefined> {
    if (realm && this.options.realmUrl && this.options.realmPlaceholder && this.options.realmUrl.length > 0) {
      const url = this.options.realmUrl
        .replace(this.options.realmPlaceholder, encodeURIComponent(realm))
        .replace(this.options.dateModifiedPlaceholder, '');
      const response = await sendAsync({
        url: url,
        method: 'GET',
        headers: {
          'X-TrackerContext-Caller': 'cc_customizationManager_getRealmOptions'
        }
      });
      if (response.request.status !== 200) {
        throw new Error(`Call to user information endpoint(${url}) failed.`);
      }

      return JSON.parse(response.request.responseText) as Partial<ICustomizationOptions>;
    }
    return;
  }

  /**
   * Update the theme and get the theme colors.
   *
   * @param {String} theme The theme identifier.
   * @returns
   */
  protected async updateThemeAndGetOptions(theme: string): Promise<Partial<ICustomizationOptions>> {
    if (!theme || !this.options.themesUrl || !this.options.themesPlaceholder) {
      this.logger.log(LogLevel.Error, 'Insufficient data provided in order to load app theme');
      throw new Error('Insufficient data provided in order to load app theme');
    }

    const existingEl = this.window.document.head.querySelector<HTMLLinkElement>('link[data-theme]');
    if (existingEl) {
      if (existingEl.getAttribute('data-theme') === theme) {
        return this._getStyleOptions();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        existingEl.parentElement!.removeChild(existingEl);
      }
    }

    const url = this.options.themesUrl
      .replace(this.options.themesPlaceholder, encodeURIComponent(theme))
      .replace(this.options.dateModifiedPlaceholder, encodeURIComponent(''));

    try {
      await loadResource(
        this.window.document,
        url,
        false,
        false,
        { 'data-theme': theme },
        this.options.stylesLoadTimeout
      );
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const linkEl = this.window.document.head.querySelector<HTMLLinkElement>('link[data-theme]')!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      linkEl.parentElement!.removeChild(linkEl);
      throw error;
    }

    return this._getStyleOptions();
  }

  /**
   * Get user related configuration.
   *
   * @param {IUserData} user The current user.
   */
  protected async getUserOptions(user: IUserData | null): Promise<Partial<ICustomizationOptions> | undefined> {
    return Promise.resolve(user?.profile || {});
  }

  /**
   * When implemented provides a way to change the options at the last possible step.
   *
   * @param {ICustomizationOptions} current Current customization options.
   */
  protected overrideOptions(current: ICustomizationOptions): Promise<ICustomizationOptions> {
    return Promise.resolve(current);
  }

  private async _getOptionsCore(user: IUserData | null): Promise<ICustomizationOptions> {
    const queryOptions = this.getQueryOptions();
    const remoteResults = await Promise.all([
      this._logAndContinue(() => this.getRealmOptions(queryOptions.realm)),
      this._logAndContinue(() => this.getUserOptions(user))
    ]);
    const result: CustomizationOptions = new CustomizationOptions(queryOptions);
    for (const remoteResult of remoteResults) {
      result.overrideValues(remoteResult);
    }

    if (queryOptions['__debug-culture']) {
      result.overrideValues({
        culture: queryOptions['__debug-culture']
      });
    }

    const themeSettings = await this._logAndContinue(() => this.updateThemeAndGetOptions(result.theme)) ?? this._getStyleOptions();
    result.overrideValues(themeSettings);

    const ianaTimeZone = await this._logAndContinue(() => this.convertToIanaTimezone(result.timeZone, result.country)) ?? result.timeZone;
    result.overrideValues({ timeZone: ianaTimeZone });

    return (await this._logAndContinue(() => this.overrideOptions(result))) ?? result;
  }

  /**
   * @inheritdoc
   */
  public initialize(): Promise<ICustomizationManager> {
    return Promise.resolve(this);
  }

  /**
   * @inheritdoc
   */
  public getConfiguration(): Promise<ICustomizationOptions> {
    if (!this._optionsPromise) {
      this._optionsPromise = this._getOptionsCore(this._userData);
    }
    return this._optionsPromise;
  }
  /**
   * @inheritdoc
   */
  public refreshConfiguration(user: IUserData | null): void {
    const newSub = user?.profile?.sub ?? '';
    const userExpires = user?.expiresAt || 0;

    if (this._userSub === newSub && this._userExpires === userExpires) {
      return;
    }

    this._userSub = newSub;
    this._userExpires = userExpires;
    this._userData = user;
    const optionsPromise = this._getOptionsCore(user);
    if (!this._optionsPromise) {
      this._optionsPromise = optionsPromise;
    } else {
      this._optionsPromise
        .then(() => {
          this._optionsPromise = optionsPromise;
        })
        .catch(err => {
          this.logger.log(LogLevel.Error, 'CustomizationManager._getOptionsCore failed', err);
          this._optionsPromise = optionsPromise;
        });
    }

  }
  /**
   * @inheritdoc
   */
  public dispose(): Promise<void> {
    return Promise.resolve();
  }
}

